<script setup>
/**
 * The <Tab> component is rendered as a child of the <Tabs> and uses CSS grid positioning so that
 * every <Tab> is rendered in the second row of the parent <Tabs> component, on top of each other.
 * When showing/hiding tabs, the previous tab fades out first, and then the next tab fades in
 * after the first has faded out.
 */

const props = defineProps({
  classNames: {
    type: String,
    default: null,
  },
  /**
   *  @todo Should this always be true? Or turned on by default?
   */
  containOverscroll: {
    type: Boolean,
    default: false,
  },
  href: {
    type: String,
    default: null,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  onClick: {
    type: Function,
    default: null,
  },
  title: {
    type: String,
    default: 'Tab',
  },
  to: {
    type: String,
    default: null,
  },
});

const containerClass = [
  'tw-col-start-1 tw-row-start-2',
  'tw-overflow-y-auto hide-scrollbar-on-mobile',
  props.containOverscroll ? 'tw-overscroll-contain' : null,
];
</script>

<template>
  <transition
    enter-active-class="fade-enter-transition"
    enter-from-class="fade-out"
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div
      v-if="isActive"
      :class="containerClass"
    >
      <slot />
    </div>
  </transition>
</template>
